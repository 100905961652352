var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"text-h4",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.productReservations,"item-key":"uniqueId","hide-default-footer":true,"disable-pagination":"","header-props":{
      'sort-by-text': 'ordenar por',
    },"loading":_vm.loading,"loading-text":"Cargando tabla...","loader-height":"2"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.paymentDeadline",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.paymentDeadline))+" ")]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.date))+" ")]}},{key:"item.totalPaidAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToCurrency(item.totalPaidAmount))+" ")]}},{key:"item.pendingPaymentAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToCurrency(item.pendingPaymentAmount))+" ")]}},{key:"item.totalPaymentAmount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToCurrency(item.totalPaymentAmount))+" ")]}},{key:"item.button",fn:function(ref){
    var item = ref.item;
return [_c('v-container',[_c('Button',{attrs:{"text":"Pagar","block":"","aria-label":"Ir al pago de la reservación","url":item.productType == 'Servicio'
              ? 'UserPayment'
              : 'UserPackagePayment',"url-params":{ id: item.id }}})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay pagos pendientes ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }