<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="productReservations"
      item-key="uniqueId"
      :hide-default-footer="true"
      disable-pagination
      :header-props="{
        'sort-by-text': 'ordenar por',
      }"
      :loading="loading"
      loading-text="Cargando tabla..."
      loader-height="2"
      class="text-h4"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.paymentDeadline`]="{ item }">
        {{ dateFormatter(item.paymentDeadline) }}
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormatter(item.date) }}
      </template>

      <template v-slot:[`item.totalPaidAmount`]="{ item }">
        {{ numberToCurrency(item.totalPaidAmount) }}
      </template>

      <template v-slot:[`item.pendingPaymentAmount`]="{ item }">
        {{ numberToCurrency(item.pendingPaymentAmount) }}
      </template>

      <template v-slot:[`item.totalPaymentAmount`]="{ item }">
        {{ numberToCurrency(item.totalPaymentAmount) }}
      </template>

      <template v-slot:[`item.button`]="{ item }">
        <v-container>
          <Button
            text="Pagar"
            block
            aria-label="Ir al pago de la reservación"
            :url="
              item.productType == 'Servicio'
                ? 'UserPayment'
                : 'UserPackagePayment'
            "
            :url-params="{ id: item.id }"
          />
        </v-container>
      </template>

      <template v-slot:no-data> No hay pagos pendientes </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  name: "PendingTable",
  components: {
    Button,
  },
  props: {
    filterResult: {
      type: Number,
      default: 0,
    },
    filterStatusReservation: {
      type: [String, Array],
      default: "",
    },
    disableButtonStatus: {
      type: Boolean,
      default: false,
    },
    productReservations: { type: Array, required: true },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      singleSelect: null,
      selected: [],
      search: "",
      headers: [
        {
          text: "Producto",
          value: "name",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Fecha límite",
          value: "paymentDeadline",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Fecha de evento",
          value: "date",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Pagado",
          value: "totalPaidAmount",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Pendiente",
          value: "pendingPaymentAmount",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "Total",
          value: "totalPaymentAmount",
          class: "text-h3 black--text font-weight-bold",
        },
        {
          text: "",
          value: "button",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>

<style scoped>
.v-data-table >>> td,
.tableFontChange {
  font-size: 18px !important;
}
</style>
