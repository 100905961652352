<template>
  <v-pagination
    v-if="pages > 0"
    v-model="localPage"
    :length="pages"
    :color="color"
    prev-icon="mdi-chevron-left"
    next-icon="mdi-chevron-right"
    @input="$emit('page-changed', localPage)"
  />
</template>
<script>
export default {
  name: "Pagination",
  props: {
    page: { type: Number, required: true },
    arrLength: { type: Number, required: true },
    perPage: { type: Number, default: 8 },
    color: { type: String, default: "primary" },
  },
  emits: ["page-changed"],
  data: () => ({
    localPage: 1,
  }),
  computed: {
    pages() {
      return Math.ceil(this.arrLength / this.perPage);
    },
  },
  watch: {
    page: function (val) {
      this.localPage = val;
    },
  },
};
</script>
<style lang="scss" scoped></style>
