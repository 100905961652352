<template>
  <v-row no-gutters class="my-1">
    <v-col cols="12" md="">
      <div class="hidden-sm-and-down pl-3">
        <router-link
          class="text-body-1 text-decoration-underline font-weight-black"
          style="color: inherit"
          :to="getReservationRoute"
        >
          #{{
            product.productType === "Servicio"
              ? product.reservationId
              : product.id
          }}
        </router-link>
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">Recibo</div>
        <span class="hidden-xs-only font-weight-bold">Recibo: </span>
        <router-link
          class="text-body-1 text-decoration-underline font-weight-black"
          style="color: inherit"
          :to="getReservationRoute"
        >
          #{{
            product.productType === "Servicio"
              ? product.reservationId
              : product.id
          }}
        </router-link>
      </div>
    </v-col>
    <v-col cols="12" md="">
      <div class="text-body-1 hidden-sm-and-down">
        {{ product.name }}
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">Producto</div>
        <span class="hidden-xs-only font-weight-bold">Producto: </span>
        {{ product.name }}
      </div>
    </v-col>
    <v-col cols="12" md="">
      <div class="text-body-1 hidden-sm-and-down pl-10">
        {{ product.payments.length }}
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">No. Pagos</div>
        <span class="hidden-xs-only font-weight-bold">No. Pagos: </span>
        {{ product.payments.length }}
      </div>
    </v-col>
    <v-col cols="12" md="">
      <div class="text-body-1 hidden-sm-and-down">
        {{ dateFormatter(product.date) }}
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">Fecha evento</div>
        <span class="hidden-xs-only font-weight-bold">Fecha evento: </span>
        {{ dateFormatter(product.date) }}
      </div>
    </v-col>
    <v-col cols="12" md="">
      <div class="text-body-1 hidden-sm-and-down">
        {{ dateFormatter(product.paymentDeadline) }}
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">Fecha liquidación</div>
        <span class="hidden-xs-only font-weight-bold">Fecha liquidación: </span>
        {{ dateFormatter(product.paymentDeadline) }}
      </div>
    </v-col>
    <v-col cols="12" md="">
      <div class="text-body-1 hidden-sm-and-down">
        {{ numberToCurrency(product.totalPaymentAmount) }}
      </div>
      <div class="text-body-1 hidden-md-and-up">
        <div class="hidden-sm-only font-weight-bold">Total</div>
        <span class="hidden-xs-only font-weight-bold">Total: </span>
        {{ numberToCurrency(product.totalPaymentAmount) }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  name: "ConcludedPaymentHeader",
  props: {
    product: { type: Object, required: true },
  },
  computed: {
    getReservationRoute() {
      return {
        name:
          this.product.productType === "Servicio"
            ? "UserReceiptDetails"
            : "UserPackageReceiptDetails",
        params: {
          id:
            this.product.productType === "Servicio"
              ? this.product.reservationId
              : this.product.id,
        },
      };
    },
  },
  methods: {
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>
<style lang="scss" scoped></style>
