<template>
  <div>
    <div v-if="filter === false">
      <v-row class="ml-6 mr-16 d-none d-md-flex" align="end" no-gutters>
        <v-col>
          <div class="text-h3 font-weight-bold">Recibo</div>
        </v-col>
        <v-col>
          <div class="text-h3 font-weight-bold">Producto</div>
        </v-col>
        <v-col>
          <div class="text-h3 font-weight-bold">No. Pagos</div>
        </v-col>
        <v-col>
          <div class="text-h3 font-weight-bold">Fecha evento</div>
        </v-col>
        <v-col>
          <div class="text-h3 font-weight-bold">Fecha liquidación</div>
        </v-col>
        <v-col>
          <div class="text-h3 font-weight-bold">Total</div>
        </v-col>
      </v-row>
      <v-expansion-panels
        v-if="productReservations.length > 0"
        v-model="activePanels"
        class="mb-5 rounded-md"
        flat
      >
        <v-expansion-panel
          v-for="(product, id) in productsReservationsPage"
          :key="id"
          active-class="header-color"
          class="expansion-border mb-7 rounded-md"
          :ripple="false"
        >
          <v-expansion-panel-header :ripple="false" flat>
            <ConcludedPaymentHeader :product="product" />
            <template v-slot:actions>
              <v-icon x-large color="secondary">mdi-chevron-down</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-content-border">
            <ConcludedPaymentRow
              :payments="product.payments"
              :reservation-status="product.reservationStatus"
              class="mr-md-10"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else class="mt-1">
        <v-divider></v-divider>
        <div class="text-body-1 text--disabled text-center mt-2">
          No hay pagos concluidos
        </div>
      </div>
      <Pagination
        :page="page"
        :arr-length="productReservations.length"
        :per-page="perPage"
        color="secondary"
        @page-changed="changePage"
      />
    </div>
    <!-- <div v-else>
      <UserPaymentsCategories
        v-for="(cat, id) in categories"
        :key="id"
        :services="filteredServices(cat)"
        :category="cat"
        type="Concluded"
      />
    </div> -->
  </div>
</template>
<script>
import Pagination from "../../Shared/Pagination.vue";
import ConcludedPaymentHeader from "./ConcludedPaymentHeader.vue";
import ConcludedPaymentRow from "./ConcludedPaymentRow.vue";
// import UserPaymentsCategories from "./UserPaymentsCategories.vue";
export default {
  name: "ConcludedPayments",
  components: {
    Pagination,
    ConcludedPaymentHeader,
    ConcludedPaymentRow,
    // UserPaymentsCategories,
  },
  props: {
    productReservations: { type: Array, required: true },
    filter: { type: Boolean, required: true },
  },
  data: () => ({
    activePanels: [],
    page: 1,
    perPage: 7,
  }),
  computed: {
    productsReservationsPage() {
      const start = (this.page - 1) * this.perPage;
      let end = this.page * this.perPage;
      if (this.mergedProducts.length < end) {
        end = this.mergedProducts.length;
      }
      return this.mergedProducts.slice(start, end);
    },
    mergedProducts() {
      return this.productReservations.map((product) => {
        return {
          ...product,
          reservationStatus: product.reservationStatus || product.status,
        };
      });
    },
    // categories: function () {
    //   let _categories = [];
    //   this.services.forEach((service) => {
    //     if (service.category) {
    //       let repeated = _categories.includes(service.category);
    //       if (!repeated) {
    //         _categories.push(service.category);
    //       }
    //     }
    //   });
    //   return _categories;
    // },
  },
  watch: {
    filter: function () {
      this.page = 1;
      this.activePanels = [];
    },
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    // filteredServices(category) {
    //   return this.services.filter(
    //     (service) => service.category === category
    //   );
    // },
  },
};
</script>
<style lang="scss" scoped>
.header-color .v-expansion-panel-header {
  background: rgba(98, 37, 130, 0.1);
}
.expansion-border {
  border: 1px solid #21145f;
}
.expansion-content-border {
  border-top: 1px solid #21145f;
}
.v-expansion-panel-content {
  padding: 0;
}
</style>
