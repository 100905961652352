<template>
  <div>
    <div v-if="!filter">
      <PaymentProgress
        v-if="productReservations.length > 0"
        :total="totalAmount"
        :paid="paidAmount"
        class="mx-2"
      />
      <PendingTable
        :product-reservations="productReservations"
        :loading="loading"
        class="mt-4"
      />
    </div>
    <!-- <div v-else>
      <div v-for="(cat, id) in categories" :key="id">
        <UserPaymentsCategories
          :services="filteredServices(cat)"
          :category="cat"
          type="Pending"
        />
      </div>
    </div> -->
  </div>
</template>
<script>
import PaymentProgress from "./PaymentProgress.vue";
import PendingTable from "./PendingTable.vue";
// import UserPaymentsCategories from "./UserPaymentsCategories.vue";
export default {
  components: {
    PendingTable,
    PaymentProgress,
    // UserPaymentsCategories
  },
  props: {
    productReservations: { type: Array, required: true },
    totalAmount: { type: Number, required: true },
    paidAmount: { type: Number, required: true },
    loading: {
      type: Boolean,
      required: true,
    },
    filter: { type: Boolean, required: true },
  },
  // computed: {
  //   categories: function () {
  //     let _categories = [];
  //     this.events.forEach((ser) => {
  //       if (ser.category) {
  //         let repeated = _categories.includes(ser.category);
  //         if (!repeated) {
  //           _categories.push(ser.category);
  //         }
  //       }
  //     });
  //     return _categories;
  //   },
  // },
  // methods: {
  //   filteredServices(category) {
  //     return this.services.filter((service) => service.category === category);
  //   },
  // },
};
</script>
<style lang="scss" scoped></style>
