<template>
  <div>
    <v-row
      v-for="(payment, id) in payments"
      :key="id"
      no-gutters
      class="py-4 expansion-divider"
    >
      <!-- Payment type -->
      <v-col cols="12" md="">
        <div class="text-body-2 text-sm-body-1 text-md-body-2 font-weight-bold">
          {{ getPaymentLabel(id) }}
        </div>
      </v-col>
      <v-col md="" />
      <!-- Number of payments -->
      <v-col cols="12" md="">
        <div class="text-body-2 text-sm-body-1 text-md-body-2 font-weight-bold">
          <div>
            No. de pago<span v-if="$vuetify.breakpoint.sm">:</span>
            <div class="pl-md-3 text-body-1 text-md-h4 font-weight-regular">
              {{ `${id + 1}` }}/{{ payments.length }}
            </div>
          </div>
        </div>
      </v-col>
      <!-- Payment date -->
      <v-col cols="12" md="">
        <div class="text-body-2 text-sm-body-1 text-md-body-2">
          <div class="font-weight-bold">
            Fecha de pago<span v-if="$vuetify.breakpoint.sm">:</span>
          </div>
          <div class="text-body-1 text-md-h4">
            {{ dateFormatter(payment.date) }}
          </div>
        </div>
      </v-col>
      <v-col md=""> </v-col>
      <!-- Payment quantity -->
      <v-col md="">
        <div class="text-body-2 text-sm-body-1 text-md-body-2">
          <div class="font-weight-bold">
            Cantidad pagada<span v-if="$vuetify.breakpoint.sm">:</span>
          </div>
          <div class="text-body-1 text-md-h4">
            {{ numberToCurrency(payment.total) }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  name: "ConcludedPaymentRow",
  props: {
    payments: { type: Array, required: true },
    reservationStatus: { type: String, required: true },
  },
  methods: {
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    numberToCurrency(num) {
      return numberToCurrency(num);
    },
    getPaymentLabel(id) {
      const isLastPayment = id === this.payments.length - 1;
      const isSpecialStatus = ["paid", "completed"].includes(
        this.reservationStatus
      );
      if (this.reservationStatus === "paid" && isLastPayment) {
        return id === 0 ? "Pago único" : "Liquidación";
      }
      return isLastPayment && isSpecialStatus
        ? "Liquidación"
        : id === 0
        ? "Anticipo"
        : "Pago de abono";
    },
  },
};
</script>
<style lang="scss" scoped>
.expansion-divider:not(:last-child) {
  border-bottom: 1px solid #21145f;
}
</style>
