<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      class="body-container-80"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div class="text-h1 font-weight-black mb-5">Pagos</div>
        <v-tabs
          v-model="tab"
          color="secondary"
          background-color="transparent"
          show-arrows
          slider-size="5"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item, i) in tabs"
            :key="i"
            class="btn-background-transparent text-not-uppercase black--text text-h4 text-sm-h3 px-1 px-sm-6"
            :ripple="false"
            :active-class="'font-weight-bold'"
          >
            {{ item.tabTitle }}
          </v-tab>
        </v-tabs>
        <v-divider color="secondary"></v-divider>
        <div class="mt-10 mb-6">
          <div class="text-h2 font-weight-black">
            {{ tabs[tab].tabTitle }}
          </div>
          <div class="text-h4">
            {{ paymentsFound }}
            {{ paymentsFound == 1 ? " pago registrado" : " pagos registrados" }}
          </div>
        </div>
        <!-- <div class="d-flex flex-column flex-md-row mb-10">
          <Button
            text="Todos"
            outlined
            pill
            class="mr-md-3 mb-3 mb-md-0"
            :aria-label="`Mostrar todas los pagos ${tabs[tab].title}`"
          />
          <Button
            text="Por categoría"
            outlined
            pill
            class="mr-md-3 mb-3 mb-md-0"
            :aria-label="`Mostrar todas los pagos ${tabs[tab].title}`"
          />
        </div> -->
        <UserPendingPayments
          v-if="tab === 0"
          :filter="filter"
          :product-reservations="pendingProductReservations"
          :total-amount="totalAmount"
          :paid-amount="paidAmount"
          :loading="loading"
        />
        <ConcludedPayments
          v-else-if="tab === 1"
          :filter="filter"
          :product-reservations="productReservationsWithPayments"
        />
      </v-container>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navbar from "../../components/User/Shared/Navbar.vue";
import UserNavbar from "../../components/User/Shared/UserNavbar.vue";
// import Button from "@/components/Shared/Button.vue";
import Footer from "../../components/Shared/Footer.vue";
import SearchBar from "../../components/Shared/SearchBar.vue";
import ConcludedPayments from "../../components/User/Payments/ConcludedPayments.vue";
import UserPendingPayments from "../../components/User/Payments/UserPendingPayments.vue";
import { mapState } from "vuex";
import { GET_USER_EVENTS_WITH_PAYMENTS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { mergeProductsLists } from "@/Utils/Utils.js";

export default {
  name: "Payments",
  components: {
    Navbar,
    UserNavbar,
    // Button,
    Footer,
    SearchBar,
    ConcludedPayments,
    UserPendingPayments,
  },
  data() {
    return {
      tabs: [
        {
          tabTitle: "Pagos pendientes",
          title: "pendientes",
        },
        {
          tabTitle: "Concluidos",
          title: "concluidos",
        },
      ],
      tab: 0,
      totalAmount: 0,
      paidAmount: 0,
      numberOfPayments: 0,
      filter: false,
      loading: true,
      events: [],
      pendingProductReservations: [],
      productReservationsWithPayments: [],
    };
  },
  computed: mapState({
    user(state) {
      if (state.user) {
        return state.user;
      }
    },
    paymentsFound() {
      switch (this.tab) {
        case 0:
          return this.pendingProductReservations.length;
        case 1:
          return this.numberOfPayments;
        default:
          return "0";
      }
    },
  }),
  watch: {
    user: {
      async handler(user) {
        if (!user) return;
        this.fetchUserPayments(user.id);
      },
      immediate: true,
    },
  },
  methods: {
    async fetchUserPayments(userId) {
      const { data, errors } = await useQuery(GET_USER_EVENTS_WITH_PAYMENTS, {
        userId: parseInt(userId),
      });
      if (data) {
        this.serviceEvents = this.formatServiceEvents(
          data.userEventsCompleteData
        );
        this.packageReservations = this.formatPackageReservations(
          data.userPackageReservations
        );
        this.productReservationsWithPayments = mergeProductsLists(
          this.serviceEvents,
          this.packageReservations
        );
        this.setPendingProductReservations(
          this.productReservationsWithPayments
        );
      } else if (errors) {
        console.log(errors);
      }
      this.loading = false;
    },
    formatServiceEvents(events) {
      let eventData;
      return events.map((e) => {
        eventData = e.event.eventService[0].quotationRequest;
        return {
          id: eventData.id,
          reservationId: eventData.reservation.id,
          name: e.service.name,
          paymentDeadline: eventData.paymentDeadline,
          date: eventData.eventDate,
          totalPaidAmount: eventData.reservation.totalPaid,
          pendingPaymentAmount:
            eventData.totalPayment - eventData.reservation.totalPaid,
          totalPaymentAmount: eventData.totalPayment,
          reservationStatus: eventData.reservation.status,
          payments: eventData.reservation.payment,
          productType: "Servicio",
        };
      });
    },
    formatPackageReservations(reservations) {
      return reservations.map((reservation) => {
        let payments = reservation.reservationPayments.map((payment) => {
          return {
            total: payment.total / 100,
            date: payment.date,
            paid: payment.paid,
          };
        });

        return {
          id: reservation.id,
          name: reservation.packageName,
          paymentDeadline: reservation.paymentDeadline,
          date: reservation.eventDate,
          totalPaidAmount: reservation.totalPaid / 100,
          pendingPaymentAmount:
            (reservation.totalPayment - reservation.totalPaid) / 100,
          totalPaymentAmount: reservation.totalPayment / 100,
          status: reservation.status,
          payments: payments,
          productType: "Paquete",
        };
      });
    },
    setPendingProductReservations(reservations) {
      return reservations.map((reservation) => {
        if (reservation.pendingPaymentAmount > 0) {
          this.pendingProductReservations.push(reservation);
          this.paidAmount += reservation.totalPaidAmount;
          this.totalAmount += reservation.totalPaymentAmount;
        }
        this.numberOfPayments += reservation.payments.length;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.btn-background-purple {
  background-color: rgba(98, 37, 130, 0.1) !important;
}

.subtitle {
  font-size: 30px;
}

.text-not-uppercase {
  text-transform: none !important;
}
</style>
