<template>
  <div v-if="!filter">
    <v-progress-linear
      :value="progress"
      color="secondary"
      class="rounded-xl"
      height="15"
    />

    <div class="d-flex mt-2">
      <div class="text-h4 font-weight-regular">Pagado {{ paidAmount }}</div>
      <v-spacer />
      <div class="text-h4 font-weight-regular">Pendiente {{ owedAmount }}</div>
    </div>
  </div>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "PaymentProgress",
  props: {
    total: { type: Number, required: true },
    paid: { type: Number, required: true },
  },
  data: () => ({
    filter: false,
  }),
  computed: {
    progress: function () {
      return (this.paid / this.total) * 100;
    },
    owedAmount: function () {
      return numberToCurrency(this.total - this.paid);
    },
    paidAmount: function () {
      return numberToCurrency(this.paid);
    },
  },
};
</script>
<style scoped lang="scss"></style>
